<template>
    <div class="con-wrap">

        <CarrotTitle title="휴가 내역"></CarrotTitle>
        <div class="board">
            <div class="mt-40">

                <div class="mt-10">
                    <table class="table-row">
                        <colgroup>
                            <col style="width:160px">
                            <col style="width:440px">
                            <col style="width:160px">
                            <col style="width:440px">
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>기간</th>
                                <td>
                                    <carrot-date-picker v-model="bbs.sdate" class="w-110px dp-inline"></carrot-date-picker>
                                    ~
                                    <carrot-date-picker v-model="bbs.edate" class="w-110px dp-inline"></carrot-date-picker>
                                </td>
                                <th>휴가종류</th>
                                <td><select v-model="bbs.vtype">
                                    <option value="">선택</option>
                                    <option value="annual">연차</option>
                                    <option value="rookie">루키</option>
                                    <option value="outing">아웃팅</option>
                                    <option value="compassionate">경조휴가</option>
                                    <option value="maternity">산전 후 휴가</option>
                                    <option value="maternity2">배우자출산</option>
                                    <option value="biztrip">출장</option>
                                    <option value="education">교육</option>
                                    <option value="workshop">워크샵</option>
                                    <option value="reservedmili">병역훈련</option>
                                    <option value="medicheck">백신휴가</option>
                                    <option value="comptime">휴일근무</option>
                                </select></td>
                            </tr>
                            <tr>
                                <th>본부</th>
                                <td><carrot-dept v-model="bbs.office"></carrot-dept></td>
                                <th>이름</th>
                                <td><input type="text" v-model="bbs.stext" class="w-100per"></td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn-default float-right mt-10" @click="bbs.doSearch">검색</button>
                    <div class="clear"></div>
                </div>

                <div class="mt-40">
                    <select class="w-150px" v-model="bbs.year" @change="bbs.changeYear()">
                        <option :value="v" v-for="(v, k) in bbs.search_years" :key="k">{{ v }}</option>
                    </select>
                    <select v-model="bbs.state" class="ml-10" @change="bbs.doSearch">
                        <option value="DUTY">재직자</option>
                        <option value="QUIT">퇴직자</option>
                    </select>
                    <button type="button" @click="bbs.downExcel" class="btn-default float-right">엑셀 다운로드</button>
                    <div class="clear"></div>
                </div>
                <div class="mt-20">
                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="160">
                            <col width="160">
                            <col width="160">
                            <col width="*">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>부문</th>
                                <th>본부</th>
                                <th>팀</th>
                                <th>성명</th>
                                <th>휴가종류</th>
                                <th>구분</th>
                                <th>휴가시작</th>
                                <th>휴가종료</th>
                                <th>휴가일수</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.num }}</td>
                                <td>{{ irow.sector_name }}</td>
                                <td>{{ irow.office_name }}</td>
                                <td class="txt-ellipsis">{{ irow.team_name }}</td>
                                <td><router-link :to="{ name:'myPIMS-VacationMgrInfo-idx', params:{ idx:irow.idx_hq } }" target="_blank"><span class="btn-view">{{ irow.ename + '(' + irow.kname + ')' }}</span></router-link></td>
                                <td>{{ irow.vtype_txt }}</td>
                                <td>{{ irow.utype_txt }}</td>
                                <td>{{ irow.sdate }}</td>
                                <td>{{ irow.edate }}</td>
                                <td>{{ irow.days }}</td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="9">휴가 내역이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import Swal from 'sweetalert2';
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import CarrotDept from '@/components/common/CarrotDept.vue'
import { useStore } from 'vuex';


export default {
    layout:"myPIMS",
    components: {
        CarrotDatePicker,
        CarrotDept,
    },
    setup() {
        const router = new useRouter();
        const toast  = useToast();
        const store  = useStore();

        const bbs = reactive({
            search_years : [],

            page  : 1,
            rows  : 50,

            sdate  : "",
            edate  : "",
            vtype  : "",
            office : 0,
            stext  : "",
            year   : "",
            state  : "DUTY",

            list     : [], total : 0,

            makeSearchYears: () => {
                let cd = new Date();
                let cy = cd.getFullYear();
                for(let i=cy; i>=2003; i--){
                    bbs.search_years.push(i);
                }
            },

            changeYear : () => {
                bbs.page=1;
                bbs.doSearch();
            },

            downExcel : () => {
                let url = "http://local.intranet.mxm.kr/excel/vacation_log?office=" + bbs.office + "&sdate=" + bbs.sdate + "&edate=" + bbs.edate + "&state=" + bbs.state + "&stext=" + bbs.stext + "&utype=" + bbs.utype;
                window.open(url, '_blank').focus();
            },

            doSearch : () => {
                let params = {
                    page : bbs.page,
                    rows : bbs.rows,

                    sdate      : bbs.sdate,
                    edate      : bbs.edate,
                    vtype      : bbs.vtype,
                    idx_office : bbs.office,
                    stext      : bbs.stext,
                    year       : bbs.year,
                    state      : bbs.state,
                };

                axios.get("/rest/mypims/getVacationLog", { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

        });

        onMounted(() => {
            // Mounted
            if( store.state.pd_auth != true ) {
                router.go(-1);
                Swal.fire({
                    title : '휴가 내역',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }

            let td = new Date();
            bbs.year = td.getFullYear();

            bbs.makeSearchYears();
            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
.btn-search {
    float:left; margin-top:13px; margin-left:10px;
}
</style>